import { useEffect,useState  } from "react";


const Events = ()=>{


    return <>
        <h1>hi</h1>
    
    </>
}


export default Events;